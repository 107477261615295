import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { tqlElementValidator } from '../validators/tql-element-validator';
import { tqlQueryValidator } from '../validators/tql-query-validator';
import { GenericOption, GenericOptionGroup, GenericOptionValue } from './option.model';
import { DateTime } from 'luxon';
import { ParameterType } from './search-parameter.model';
import { GroupByKey, GroupByKeyLegacy } from '../utils/key-translator';
import { AcctGroupTypeOption } from './balances-grouping-view.model';
import { CashPositionGrouping } from '@trovata/app/features/cash-position/models/cash-position.model';

export abstract class TQLElement {
	type: TQLElementType;

	focus$: Subject<void>;

	abstract isValid(): boolean;

	abstract reset(): void;

	abstract unsubscribe(): void;

	abstract abstractControl: AbstractControl;

	destroyed$: Subject<void>;

	constructor(type: TQLElementType) {
		this.type = type;
		this.focus$ = new Subject();
		this.destroyed$ = new Subject();
	}
}

export class TQLQuery extends TQLElement {
	changed$: Subject<void>;

	abstractControl: FormGroup;
	tqlElements: TQLElement[];

	blankElementIndex: number;

	startDate: DateTime;
	endDate: DateTime;

	tqlFields: TQLFieldDict;
	tqlOperators: TQLOperatorDict;

	nestedLevel: number;
	isRoot: boolean;
	containsNestedQueries: boolean;

	private formBuilder: FormBuilder;

	constructor(nestedLevel: number = 0) {
		super(TQLElementType.query);
		this.formBuilder = new FormBuilder();
		this.tqlElements = [];
		this.nestedLevel = nestedLevel;
		this.containsNestedQueries = false;
		this.changed$ = new Subject();
		this.setBlankElementIndex(0);
		this.createQueryForm();
	}

	addNewQueryElement(
		type: TQLElementType,
		prefill?: any,
		valueType?: TQLValueControlType,
		index?: number,
		autofocus?: boolean,
		replaceElement?: boolean,
		incrementBlankElementIndex?: boolean
	): TQLElement {
		let newElement: TQLElement;
		const newIndex: number = index || index === 0 ? index : this.blankElementIndex;
		switch (type) {
			case TQLElementType.operator:
				const lastIndex: number = newIndex - 1;
				const lastElement: TQLElement = this.tqlElements[lastIndex];
				if (!lastElement || lastElement.type === TQLElementType.query) {
					newElement = new TQLOperator(null, prefill, autofocus);
				} else {
					newElement = new TQLOperator(lastElement?.abstractControl?.value, prefill, autofocus);
				}
				break;
			case TQLElementType.property:
				newElement = new TQLProperty(this.tqlFields, prefill, autofocus);
				break;
			case TQLElementType.query:
				newElement = new TQLQuery(this.nestedLevel + 1);
				if (newElement instanceof TQLQuery) {
					newElement.tqlFields = this.tqlFields;
					newElement.tqlOperators = this.tqlOperators;
				}
				break;
			case TQLElementType.value:
				const property: TQLPropertyKey = this.tqlElements[newIndex - 2].abstractControl?.value?.option?.id;
				newElement = new TQLValue(valueType, prefill, property, autofocus);
				break;
		}

		this.handleElementValidation(newElement, newIndex, replaceElement);
		if (replaceElement) {
			this.tqlElements[newIndex] = newElement;
		} else {
			this.tqlElements.splice(newIndex, 0, newElement);
		}
		if (prefill || incrementBlankElementIndex) {
			this.setBlankElementIndex(newIndex + 1);
		} else {
			this.setBlankElementIndex(null);
		}
		setTimeout(() => {
			this.changed$.next();
		});
		return newElement;
	}

	setBlankElementIndex(newIndex: number): void {
		this.blankElementIndex = newIndex;
	}

	unsubscribe(): void {
		this.destroyed$.next();
		this.tqlElements.forEach((element: TQLElement) => {
			element.unsubscribe();
		});
	}

	private handleElementValidation(element: TQLElement, elementIndex: number, replaceElement?: boolean): void {
		const queryItemArray: FormArray = this.abstractControl.get('queryItems') as FormArray;
		element.abstractControl.statusChanges.pipe(takeUntil(this.destroyed$)).subscribe(() => {
			this.abstractControl.updateValueAndValidity();
		});
		if (element instanceof TQLInput) {
			element.subscribeToStatus();
		}
		if (replaceElement) {
			queryItemArray.removeAt(elementIndex);
		}
		queryItemArray.insert(elementIndex, element.abstractControl);
	}

	// ungroups itself and becomes normal unnested query with first child query's elements
	private ungroup(): void {
		const nestedQuery: TQLElement = this.tqlElements[0];
		if (nestedQuery instanceof TQLQuery) {
			this.abstractControl.controls = nestedQuery.abstractControl.controls;
			this.containsNestedQueries = false;
			this.tqlElements = nestedQuery.tqlElements;
			this.setBlankElementIndex(4);
			setTimeout(() => {
				this.changed$.next();
			});
		}
	}

	// this can be refactored into a recursive fn()
	pasteQuery(tqlQuery: TQLQuery, pasteIndex: number): void {
		const newQuery: TQLElement = this.addNewQueryElement(TQLElementType.query, null, null, pasteIndex);
		if (newQuery instanceof TQLQuery) {
			newQuery.containsNestedQueries = tqlQuery.containsNestedQueries;
			this.addQueryElementsToQuery(tqlQuery, newQuery);
		}
	}

	ungroupChildNestedQuery(childIndex: number): void {
		const childNestedQuery: TQLElement = this.tqlElements[childIndex];
		if (childNestedQuery instanceof TQLQuery) {
			this.removeElement(childIndex);
			this.addQueryElementsToQuery(childNestedQuery, this, childIndex);
		}
	}

	private addQueryElementsToQuery(fromQuery: TQLQuery, toQuery: TQLQuery, startIndex: number = 0): void {
		fromQuery.tqlElements.forEach((tqlElement: TQLElement, elementIndex: number) => {
			if (tqlElement instanceof TQLQuery) {
				const newNestedQuery: TQLElement = toQuery.addNewQueryElement(TQLElementType.query, null, null, elementIndex + startIndex);
				if (newNestedQuery instanceof TQLQuery) {
					this.addQueryElementsToQuery(tqlElement, newNestedQuery);
				}
			} else if (tqlElement instanceof TQLValue) {
				toQuery.addNewQueryElement(tqlElement.type, tqlElement.abstractControl.value, tqlElement.valueType, elementIndex + startIndex);
			} else {
				toQuery.addNewQueryElement(tqlElement.type, tqlElement.abstractControl.value, null, elementIndex + startIndex);
			}
		});
	}

	createNestedQuery(): void {
		this.containsNestedQueries = true;
		const newQuery: TQLQuery = new TQLQuery(this.nestedLevel + 1);
		newQuery.tqlFields = this.tqlFields;
		this.tqlElements.forEach((tqlElement: TQLElement) => {
			if (tqlElement instanceof TQLValue) {
				newQuery.addNewQueryElement(tqlElement.type, tqlElement.abstractControl.value, tqlElement.valueType);
			} else {
				newQuery.addNewQueryElement(tqlElement.type, tqlElement.abstractControl.value);
			}
		});
		this.reset();
		this.handleElementValidation(newQuery, 0);
		this.setBlankElementIndex(null);
		this.tqlElements.push(newQuery);
		setTimeout(() => {
			this.changed$.next();
		});
	}

	isValid(): boolean {
		return this.abstractControl.status !== 'INVALID';
	}

	// return true if query is over a certain length of terms
	canDeactivate(): boolean {
		if (!this.isRoot) {
			return null;
		}
		return this.countTQLLeaves() < 4;
	}

	private countTQLLeaves(): number {
		let countQueries: number = 0;
		let hasQueries: boolean = false;
		this.tqlElements?.forEach((element: TQLElement) => {
			if (element.type === TQLElementType.query) {
				countQueries += (<TQLQuery>element).countTQLLeaves();
				hasQueries = true;
			}
		});
		if (this.tqlElements?.length && !this.isRoot && !hasQueries) {
			countQueries += 1;
		}
		return countQueries;
	}

	reset(): void {
		this.clearQueryForm();
		this.tqlElements = [];
		this.changed$.next();
	}

	cleanUp(): void {
		this.abstractControl.updateValueAndValidity();
		if (this.tqlElements.length) {
			for (let i = this.tqlElements.length - 1; i >= 0; i--) {
				const element: TQLElement = this.tqlElements[i];
				const previousElement: TQLElement | undefined = this.tqlElements[i - 1];
				const nextElement: TQLElement | undefined = this.tqlElements[i + 1];

				if (element instanceof TQLQuery) {
					element.cleanUp();
				}

				if (
					!element.isValid() ||
					// if operator and last element or if current + previous element are both operators
					(element instanceof TQLOperator && (i === this.tqlElements.length - 1 || previousElement instanceof TQLOperator)) ||
					(element instanceof TQLQuery && this.isIncompleteNestedQuery(element))
				) {
					this.removeElement(i);
				}

				if (element instanceof TQLQuery && nextElement instanceof TQLQuery) {
					this.addTQLControl(TQLElementType.operator, TQLOperatorKey.and, null, null, i + 1);
				}
			}

			// remove first element if it is an operator other than not
			if (
				this.tqlElements[0] instanceof TQLOperator &&
				(this.tqlElements[0].abstractControl.value.option.id !== TQLOperatorKey.not || this.tqlElements.length === 1)
			) {
				this.removeElement(0);
			}

			// if nested query with only one query inside of it, revert to non nested query
			if (this.tqlElements.length === 1 && this.containsNestedQueries && !this.isRoot && this.tqlElements[0] instanceof TQLQuery) {
				this.ungroup();
			}
			if (this.isRoot) {
				this.setBlankElementIndex(this.tqlElements.length);
			} else if (this.containsNestedQueries && !this.isRoot) {
				this.setBlankElementIndex(null);
			}
		}
		setTimeout(() => {
			this.changed$.next();
		});
	}

	private isIncompleteNestedQuery(tqlQuery: TQLQuery): boolean {
		return (tqlQuery.containsNestedQueries && !tqlQuery.tqlElements.length) || (tqlQuery.tqlElements.length < 3 && !tqlQuery.containsNestedQueries);
	}

	removeElement(elementIndex: number): void {
		const elementToRemove: TQLElement = this.tqlElements[elementIndex];
		if (elementToRemove) {
			elementToRemove.unsubscribe();
			const queryItemArray: FormArray = this.abstractControl.get('queryItems') as FormArray;
			queryItemArray.removeAt(elementIndex);
			this.abstractControl.updateValueAndValidity();
			this.tqlElements.splice(elementIndex, 1);
			this.setBlankElementIndex(elementIndex);
		}
		setTimeout(() => {
			this.changed$.next();
		});
	}

	onNewQueryOptionSelected(option: GenericOption, group: GenericOptionGroup, tqlMode: TQLInputSource): void {
		if (group.type === TQLOptionType.operator) {
			this.addNewQueryElement(TQLElementType.operator, {
				option: option,
				group: group,
			});
		} else {
			const lastElement: TQLElement = this.tqlElements[this.blankElementIndex - 1];
			if (lastElement && lastElement.type !== TQLElementType.operator) {
				this.addTQLControl(TQLElementType.operator, TQLOperatorKey.and);
			}
			switch (group.type) {
				case TQLOptionType.property:
					const newQuery: TQLElement = this.addNewQueryElement(TQLElementType.query);
					if (newQuery instanceof TQLQuery) {
						newQuery.addNewQueryElement(TQLElementType.property, {
							option: option,
							group: group,
						});
						newQuery.onOptionSelected(option, group, null, 0, tqlMode);
					}
					break;
				case TQLOptionType.suggestion:
					const newSuggestionQuery: TQLElement = this.addNewQueryElement(TQLElementType.query, null, null, null, null, null, true);
					if (newSuggestionQuery instanceof TQLQuery) {
						const suggestionOption: TQLSuggestionOption = <TQLSuggestionOption>option;
						newSuggestionQuery.addTQLControl(TQLElementType.property, suggestionOption.property);
						newSuggestionQuery.addTQLControl(TQLElementType.operator, suggestionOption.operator);
						newSuggestionQuery.addTQLControl(TQLElementType.value, null, suggestionOption.value, TQLValueControlType.number);
						newSuggestionQuery.onOptionSelected(option, group, null, 0, tqlMode);
					}
					break;
				case TQLOptionType.string:
					const newStringQuery: TQLElement = this.addNewQueryElement(TQLElementType.query, null, null, null, null, null, true);
					if (newStringQuery instanceof TQLQuery) {
						newStringQuery.addTQLControl(TQLElementType.property, option.id);
						newStringQuery.addTQLControl(TQLElementType.operator, TQLOperatorKey.contains);
						newStringQuery.addTQLControl(TQLElementType.value, null, option.displayValue, TQLValueControlType.string);
						newStringQuery.onOptionSelected(option, group, null, 0, tqlMode);
					}
					break;
				case TQLFieldType.id:
					const fieldOptionGroup: FieldOptionGroup = <FieldOptionGroup>group;
					const newParamQuery: TQLElement = this.addNewQueryElement(TQLElementType.query, null, null, null, null, null, true);
					if (newParamQuery instanceof TQLQuery) {
						newParamQuery.addTQLControl(TQLElementType.property, fieldOptionGroup.id);
						newParamQuery.addTQLControl(TQLElementType.operator, TQLOperatorKey.in);
						newParamQuery.addTQLControl(TQLElementType.value, null, [{ option: option, group: group }], TQLValueControlType.multi);
						newParamQuery.onOptionSelected(option, group, null, 0, tqlMode);
					}
					break;
			}
		}
		setTimeout(() => {
			this.changed$.next();
		});
	}

	onOptionSelected(option: GenericOption, group: GenericOptionGroup, element: TQLElement, elementIndex: number, sourceMode: TQLInputSource): void {
		const nextIndex: number = elementIndex || elementIndex === 0 ? elementIndex + 1 : this.tqlElements.length;
		const nextElement: TQLElement = this.tqlElements[nextIndex];
		switch (group.type) {
			case TQLOptionType.property:
				// adds operator value control if there isnt already one
				if (!nextElement || !this.tqlFields[option.id].operators.includes(nextElement?.abstractControl?.value?.option?.id)) {
					const newElement: TQLElement = this.addNewQueryElement(TQLElementType.operator, null, null, nextIndex, sourceMode === TQLInputSource.pro, true);
					if (this.tqlElements[elementIndex + 2]) {
						this.removeElement(elementIndex + 2);
					}
					if (sourceMode === TQLInputSource.pro) {
						this.delayFocusElement(newElement);
					}
				} else if (nextElement && this.tqlFields[option.id].operators.includes(nextElement?.abstractControl?.value?.option?.id)) {
					const newElement: TQLElement = this.addNewQueryElement(TQLElementType.operator, nextElement.abstractControl.value, null, nextIndex, null, true);
					// removes value control if there is one
					if (this.tqlElements[elementIndex + 2]) {
						this.removeElement(elementIndex + 2);
					}
					this.onOptionSelected(newElement.abstractControl.value.option, newElement.abstractControl.value.group, newElement, nextIndex, sourceMode);
				} else {
					if (sourceMode === TQLInputSource.pro) {
						this.delayFocusElement(nextElement);
					}
				}

				break;
			case TQLOptionType.operator:
				if (element instanceof TQLOperator && element.operatesOn) {
					switch (option.id) {
						case TQLOperatorKey.startsWith:
						case TQLOperatorKey.doesNotStartWith:
						case TQLOperatorKey.contains:
						case TQLOperatorKey.doesNotContain:
						case TQLOperatorKey.endsWith:
						case TQLOperatorKey.doesNotEndWith:
							// adds string value control if there isnt already one
							if (!nextElement) {
								const newElement: TQLElement = this.addNewQueryElement(TQLElementType.value, null, TQLValueControlType.string, nextIndex, null, true);
								if (sourceMode === TQLInputSource.pro) {
									this.delayFocusElement(newElement);
								}
							}
							break;
						case TQLOperatorKey.in:
						case TQLOperatorKey.notIn:
							// adds multi select value control if there isnt already one
							if (!nextElement) {
								const newElement: TQLElement = this.addNewQueryElement(TQLElementType.value, null, TQLValueControlType.multi, nextIndex, null, true);
								if (sourceMode === TQLInputSource.pro) {
									this.delayFocusElement(newElement);
								}
							}
							break;
						case TQLOperatorKey.greaterThan:
						case TQLOperatorKey.greaterThanEqual:
						case TQLOperatorKey.lessThanEqual:
						case TQLOperatorKey.lessThan:
						case TQLOperatorKey.equal:
						case TQLOperatorKey.notEqual:
							if (element.operatesOn?.option?.type === TQLFieldType.boolean) {
								// adds boolean value control if there isnt already one
								if (!nextElement) {
									const newElement: TQLElement = this.addNewQueryElement(TQLElementType.value, null, TQLValueControlType.boolean, nextIndex, null, true);
									if (sourceMode === TQLInputSource.pro) {
										this.delayFocusElement(newElement);
									}
								}
							} else if (element.operatesOn?.option?.type === TQLFieldType.number) {
								// adds number value control if there isnt already one
								if (!nextElement) {
									const newElement: TQLElement = this.addNewQueryElement(TQLElementType.value, null, TQLValueControlType.number, nextIndex, null, true);
									if (sourceMode === TQLInputSource.pro) {
										this.delayFocusElement(newElement);
									}
								}
							} else if (element.operatesOn?.option?.type === TQLFieldType.text) {
								// adds string value control if there isnt already one
								if (!nextElement || !(nextElement instanceof TQLValue && nextElement.valueType === TQLValueControlType.string)) {
									const newElement: TQLElement = this.addNewQueryElement(TQLElementType.value, null, TQLValueControlType.string, nextIndex, null, true);
									if (sourceMode === TQLInputSource.pro) {
										this.delayFocusElement(newElement);
									}
								}
							}
							break;
						default:
							// adds generic value control if there isnt already one
							if (!nextElement || !(nextElement instanceof TQLValue)) {
								const newElement: TQLElement = this.addNewQueryElement(TQLElementType.value, null, null, nextIndex, null, true);
								if (sourceMode === TQLInputSource.pro) {
									this.delayFocusElement(newElement);
								}
							}
					}
				} else {
					if (!nextElement || !(nextElement instanceof TQLQuery)) {
						this.addNewQueryElement(TQLElementType.query, null, null, nextIndex, null, true);
					} else {
						if (sourceMode === TQLInputSource.pro) {
							nextElement.focus$.next();
						}
					}
				}
				break;
			default:
				break;
		}
		this.abstractControl.updateValueAndValidity();
		setTimeout(() => {
			this.changed$.next();
		});
	}

	private delayFocusElement(element: TQLElement): void {
		setTimeout(() => {
			element.focus$.next();
		});
	}

	// used when you want to add a control with a value using a key instead of the value object from a control
	addTQLControl(type: TQLElementType, key?: string, prefill?: any, valueType?: TQLValueControlType, index?: number, replaceElement?: boolean): TQLElement {
		let optionGroup: GenericOptionGroup;
		let controlPrefill: any;
		if (prefill) {
			controlPrefill = prefill;
		} else if (key) {
			if (type === TQLElementType.operator) {
				optionGroup = getOperatorGroup(allOperatorKeys, this.tqlOperators);
			} else if (type === TQLElementType.property) {
				optionGroup = propertyOptionGroup(Object.values(this.tqlFields));
			}

			const option: GenericOption = optionGroup.options.find((findOption: GenericOption) => findOption.id === key);
			controlPrefill = { option: option, group: optionGroup };
		}
		return this.addNewQueryElement(type, controlPrefill, valueType, index, replaceElement);
	}

	private clearQueryForm(): void {
		const queryItemArray: FormArray = this.abstractControl.get('queryItems') as FormArray;
		while (queryItemArray.length !== 0) {
			queryItemArray.removeAt(0);
		}
	}

	private createQueryForm(): void {
		this.abstractControl = this.formBuilder.group({ queryItems: this.formBuilder.array([]) }, { validator: tqlQueryValidator() });
	}
}

export class TQLInput extends TQLElement {
	abstractControl: FormControl;

	inputLabel: string;

	autoFocus: boolean;

	reset$: Subject<void>;

	hasSavedValue: boolean;
	protected previouslyValidState: GenericOptionValue | GenericOptionValue[];

	constructor(type: TQLElementType, autofocus: boolean) {
		super(type);
		this.autoFocus = autofocus;
		this.reset$ = new Subject();
	}

	subscribeToAndApplyChanges(): void {
		this.abstractControl.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(value => {
			if (this.abstractControl.status === 'VALID') {
				this.abstractControl.setValue(value, {
					onlySelf: true,
					emitEvent: false,
				});
			}
		});
	}

	restoreLastValidValue(): void {
		if (this.previouslyValidState) {
			this.abstractControl.setValue(this.previouslyValidState);
			this.hasSavedValue = true;
		}
	}

	reset(): void {
		this.abstractControl.setValue('');
		delete this.previouslyValidState;
		this.hasSavedValue = false;
	}

	isValid(): boolean {
		return this.abstractControl.status !== 'INVALID';
	}

	saveValidState(): void {
		this.previouslyValidState = this.abstractControl.value;
		this.hasSavedValue = true;
	}

	subscribeToStatus(): void {
		this.abstractControl.statusChanges.pipe(takeUntil(this.destroyed$)).subscribe((status: string) => {
			if (status === 'VALID') {
				this.saveValidState();
			}
		});
	}

	unsubscribe(): void {
		this.destroyed$.next();
	}
}

export class TQLOperator extends TQLInput {
	operatesOn: { option: TQLField; group: GenericOptionGroup };

	constructor(operatesOn?: { option: TQLField; group: GenericOptionGroup }, prefill?: GenericOptionValue, autofocus?: boolean) {
		super(TQLElementType.operator, autofocus);
		this.createFormControl();
		this.operatesOn = operatesOn;
		this.subscribeToAndApplyChanges();
		this.inputLabel = 'Operator';
		if (prefill) {
			this.abstractControl.setValue(prefill);
			this.previouslyValidState = prefill;
		}
	}

	private createFormControl(): void {
		this.abstractControl = new FormControl('', {
			validators: [Validators.required, tqlElementValidator(this.type)],
		});
	}
}

export class TQLProperty extends TQLInput {
	fields: TQLFieldDict;
	constructor(fields: TQLFieldDict, prefill?: { option: GenericOption; group: GenericOptionGroup }, autofocus?: boolean) {
		super(TQLElementType.property, autofocus);
		this.fields = fields;
		this.createFormControl();
		this.subscribeToAndApplyChanges();
		this.inputLabel = 'Property';
		if (prefill) {
			this.abstractControl.setValue(prefill);
			this.previouslyValidState = prefill;
		}
	}
	private createFormControl(): void {
		this.abstractControl = new FormControl('', {
			validators: [Validators.required, tqlElementValidator(this.type)],
		});
	}
}

// Values
export class TQLValue extends TQLInput {
	valueType: TQLValueControlType;
	property: TQLPropertyKey;

	constructor(valueType?: TQLValueControlType, prefill?: any, property?: TQLPropertyKey, autofocus?: boolean) {
		super(TQLElementType.value, autofocus);
		this.property = property;
		this.valueType = valueType;
		this.createFormControl();
		this.subscribeToAndApplyChanges();
		this.inputLabel = 'Value';
		if (prefill) {
			this.abstractControl.setValue(prefill);
			this.previouslyValidState = prefill;
		} else if (valueType === TQLValueControlType.multi) {
			this.abstractControl.setValue([]);
			this.previouslyValidState = prefill;
		}
	}

	private createFormControl(): void {
		this.abstractControl = new FormControl('', {
			validators: [Validators.required, tqlElementValidator(this.type, this.valueType)],
		});
	}
}

export enum TQLElementType {
	operator = 'operator',
	property = 'property',
	value = 'value',
	query = 'query',
}

export enum TQLValueControlType {
	boolean = 'boolean',
	number = 'number',
	string = 'string',
	filter = 'filter',
	multi = 'multi',
	date = 'date',
}

export enum TQLOperatorKey {
	contains = 'contains',
	doesNotContain = 'doesNotContain',
	startsWith = 'startsWith',
	doesNotStartWith = 'doesNotStartWith',
	endsWith = 'endsWith',
	doesNotEndWith = 'doesNotEndWith',
	and = 'and',
	or = 'or',
	orNot = 'orNot',
	andNot = 'andNot',
	not = 'not',
	in = 'in',
	// not camel case because this is how API reads NOT IN
	notIn = 'not in',
	lessThan = '<',
	lessThanEqual = '<=',
	greaterThan = '>',
	greaterThanEqual = '>=',
	equal = '==',
	notEqual = '!=',
}

export enum SymbolPlacement {
	before = 'before',
	after = 'after',
	both = 'both',
}

export class TQLPropertyOption implements GenericOption {
	displayValue: string;
	id: string;
}

export class TQLOperatorOption extends GenericOption {
	isLike: boolean | null;
	symbolPlacement: SymbolPlacement | null;
}

export interface TQLOperatorDict {
	[operatorKey: string]: TQLOperatorOption;
}

export enum TQLOptionType {
	operator = 'operator',
	property = 'property',
	suggestion = 'suggestion',
	value = 'value',
	boolean = 'boolean',
	string = 'string',
	number = 'number',
	grouping = 'grouping',
}

export enum TQLInputType {
	select = 'select',
	multi = 'multi',
	string = 'string',
	date = 'date',
	auto = 'auto',
}

export enum TQLPropertyKey {
	account = 'account',
	accountClosed = 'accountClosed',
	accountNickname = 'accountNickname',
	accountNumber = 'accountNumber',
	accountPositionType = 'accountPositionType',
	accountType = 'accountType',
	amount = 'amount',
	baiCode = 'baiCode',
	baiDescription = 'baiDescription',
	bankReferenceNumber = 'bankReferenceNumber',
	checkNumber = 'checkNumber',
	creditGlCode = 'creditGlCode',
	currency = 'currency',
	date = 'date',
	debitGlCode = 'debitGlCode',
	descriptionDetail = 'descriptionDetail',
	description = 'description',
	division = 'division',
	entity = 'entity',
	entityId = 'entityId',
	entityRegion = 'entityRegion',
	entityDivision = 'entityDivision',
	// operators
	fuzzy = 'fuzzy',
	glTag = 'glTag',
	insertedTimestamp = 'insertedTimestamp',
	institution = 'institution',
	institutionName = 'institutionName',
	isIntraday = 'isIntraday',
	manualAccount = 'manualAccount',
	manualTransaction = 'manualTransaction',
	memo = 'memo',
	region = 'region',
	tag = 'tag',
	type = 'type',
	untagged = 'untagged',
	updatedAt = 'updatedAt',
}

export const getOperators = (operatingOnElement: string, operatingOnOption: GenericOption, operators: TQLOperatorDict): GenericOptionGroup => {
	if (operatingOnElement === TQLOptionType.property) {
		const tqlField: TQLField = <TQLField>operatingOnOption;
		return getOperatorGroup(tqlField.operators, operators);
	} else {
		return getOperatorGroup(logicalOperatorKeys, operators);
	}
};

export const getOperatorGroup = (operatorKeys: TQLOperatorKey[], operators: TQLOperatorDict): GenericOptionGroup => ({
	displayType: 'Operators',
	type: TQLOptionType.operator,
	options: operatorKeys.map((operatorKey: TQLOperatorKey) => operators[operatorKey]),
});

export const logicalOperatorKeys: TQLOperatorKey[] = [TQLOperatorKey.and, TQLOperatorKey.or, TQLOperatorKey.andNot, TQLOperatorKey.orNot];

export const propertyOptionGroup: (tqlFields: TQLField[]) => GenericOptionGroup = (tqlFields: TQLField[]) => ({
	displayType: 'Property',
	type: TQLOptionType.property,
	options: tqlFields,
});

export interface TQLSuggestionOption extends GenericOption {
	value: any;
	operator: TQLOperatorKey;
	property: TQLPropertyKey;
}

export const getTextSearchOption = (value: string, propertyKey: TQLPropertyKey): GenericOption => ({
	displayValue: value,
	id: propertyKey,
});

export const getTextSearchSuggestionsGroup = (value: string, propertyKey: TQLPropertyKey, displayType: string): GenericOptionGroup => ({
	displayType: displayType,
	type: TQLOptionType.string,
	options: [getTextSearchOption(value, propertyKey)],
});

export const getTextSearchSuggestionsGroups = (value: string, tqlFieldDict: TQLFieldDict): GenericOptionGroup[] => {
	const textSuggestionGroups: GenericOptionGroup[] = [];
	const tqlPropertyToLabelMap = new Map([
		[TQLPropertyKey.description, 'Description'],
		[TQLPropertyKey.fuzzy, 'Text Search'],
		[TQLPropertyKey.baiCode, 'BAI Code'],
		[TQLPropertyKey.baiDescription, 'BAI Description'],
		[TQLPropertyKey.bankReferenceNumber, 'Bank Reference Number'],
		[TQLPropertyKey.memo, 'Memo'],
		[TQLPropertyKey.descriptionDetail, 'Description Details'],
		[TQLPropertyKey.checkNumber, 'Check Number'],
	]);

	for (const [tqlPropertyKey, label] of tqlPropertyToLabelMap.entries()) {
		if (tqlFieldDict[tqlPropertyKey]) {
			textSuggestionGroups.push(getTextSearchSuggestionsGroup(value, tqlPropertyKey, label));
		}
	}
	return textSuggestionGroups;
};

export const getAmountSuggestionsGroup = (value: number): GenericOptionGroup => {
	const options: TQLSuggestionOption[] = [
		{
			displayValue: 'Amount < ' + value,
			id: 'amountLessThanSuggestion',
			property: TQLPropertyKey.amount,
			operator: TQLOperatorKey.lessThan,
			value: value,
		},
		{
			displayValue: 'Amount > ' + value,
			id: 'amountGreaterThanSuggestion',
			property: TQLPropertyKey.amount,
			operator: TQLOperatorKey.greaterThan,
			value: value,
		},
	];
	const group: GenericOptionGroup = {
		displayType: 'Suggestions',
		type: TQLOptionType.suggestion,
		options: options,
	};
	return group;
};

export enum TQLInputSource {
	pro = 'pro',
	novice = 'novice',
	loading = 'loading',
}

export enum ElementNavEventDirection {
	previous = 'previous',
	next = 'next',
}

export interface ElementNavEvent {
	direction: ElementNavEventDirection;
	selectInputId?: string;
	incrementBlankElementIndex?: boolean;
}

export interface TQLElementFocusEvent {
	inputType: TQLInputType;
	isFocused: boolean;
	isEmptyElement: boolean;
	nestingLevel: number;
}

export enum TQLHintInnerHTML {
	pressEnterSubmit = `Press <strong>Enter</strong> to
                search`,
	pressEnterProceed = `Press <strong>Enter</strong> to
                continue`,
	shiftArrow = `Press <strong>Shift</strong> plus <strong>Arrow Key</strong> 
                to switch between inputs`,
}

export enum TQLContextAction {
	cut = 'cut',
	copy = 'copy',
	paste = 'paste',
	ungroup = 'ungroup',
}

export interface TQLContextEvent {
	action: TQLContextAction;
	query?: TQLQuery;
	elementIndex?: number;
	parentQuery?: TQLQuery;
}

export interface TQLPayload {
	type: string;
	expression: object;
}

export interface TQLField extends GenericOption {
	type: string;
	operators: TQLOperatorKey[];
	options?: GenericOption[];
}

export interface TQLFieldDict {
	[id: string]: TQLField;
}

export interface TQLValuesDict {
	[id: string]: TQLValues;
}

export interface TQLValues {
	id: string;
	values: GenericOption[];
}

export enum TQLFieldContext {
	tags = 'tags',
	glTags = 'gl-tags',
	transactions = 'transactions',
	accounts = 'accounts',
	balances = 'balances',
	balancesAnalysis = 'balances-analysis',
	transactionsAnalysis = 'transactions-analysis',
}

export interface TQLFieldsResponse {
	fields: TQLFieldDict;
	operators: TQLOperatorDict;
}

export enum TQLFieldType {
	text = 'text',
	number = 'number',
	boolean = 'boolean',
	id = 'id',
}

export interface TQLValues {
	id: string;
	values: GenericOption[];
}

export interface TqlCompletionResponseBody {
	tql: any;
	startDate?: any;
	endDate?: any;
}

export const inOperatorsKeys: TQLOperatorKey[] = [TQLOperatorKey.in, TQLOperatorKey.notIn];

export const compareOperatorKeys: TQLOperatorKey[] = [
	TQLOperatorKey.lessThan,
	TQLOperatorKey.lessThanEqual,
	TQLOperatorKey.greaterThan,
	TQLOperatorKey.greaterThanEqual,
];

export const equalityOperatorKeys: TQLOperatorKey[] = [TQLOperatorKey.equal, TQLOperatorKey.notEqual];

export const containsMatchingOperatorKeys: TQLOperatorKey[] = [TQLOperatorKey.contains, TQLOperatorKey.doesNotContain];

export const patternMatchingOperatorKeys: TQLOperatorKey[] = [
	...containsMatchingOperatorKeys,
	TQLOperatorKey.startsWith,
	TQLOperatorKey.doesNotStartWith,
	TQLOperatorKey.endsWith,
	TQLOperatorKey.doesNotEndWith,
];

export const notOperatorKeys: TQLOperatorKey[] = [TQLOperatorKey.not];

export const allOperatorKeys: TQLOperatorKey[] = [
	...logicalOperatorKeys,
	...patternMatchingOperatorKeys,
	...compareOperatorKeys,
	...equalityOperatorKeys,
	...inOperatorsKeys,
	...notOperatorKeys,
];

export const tqlFieldToGroup = (tqlField: TQLField): FieldOptionGroup => ({
	displayType: tqlField.displayValue,
	type: tqlField.type,
	options: tqlField.options,
	id: tqlField.id,
});

export type BooleanOptionGroup = GenericOptionGroup<boolean>;
export type BooleanOption = GenericOption<boolean>;

export const booleanOptions: BooleanOption[] = [
	{ displayValue: 'True', id: true },
	{ displayValue: 'False', id: false },
];

export const getBooleanGroup = (): BooleanOptionGroup => ({
	displayType: 'Boolean',
	type: TQLOptionType.boolean,
	options: booleanOptions,
});

export interface FieldOptionGroup extends GenericOptionGroup {
	id: string;
}

export const getTQLFieldFromLegacyKey = (legacyKey: string): TQLPropertyKey => {
	switch (legacyKey) {
		case ParameterType.institutionId:
		case GroupByKeyLegacy.institution:
			return TQLPropertyKey.institution;
		case ParameterType.accountId:
		case GroupByKeyLegacy.account:
			return TQLPropertyKey.account;
		case ParameterType.currency:
			return TQLPropertyKey.currency;
		case ParameterType.isManual:
			return TQLPropertyKey.manualAccount;
		case ParameterType.entityRegion:
			return TQLPropertyKey.entityRegion;
		case ParameterType.entityDivision:
			return TQLPropertyKey.entityDivision;
		case ParameterType.entityId:
			return TQLPropertyKey.entityId;
		case ParameterType.entity:
		case GroupByKeyLegacy.entityGroupingId:
			return TQLPropertyKey.entity;
		case ParameterType.region:
		case GroupByKeyLegacy.regionGroupingId:
			return TQLPropertyKey.region;
		case ParameterType.division:
		case GroupByKeyLegacy.divisionGroupingId:
			return TQLPropertyKey.division;
		case GroupByKeyLegacy.tag:
		case ParameterType.tag:
		case ParameterType.tagId:
			return TQLPropertyKey.tag;
	}
};

export const getFieldPropertyKeyFromAcctGroup: (groupType: AcctGroupTypeOption) => string = (groupType: AcctGroupTypeOption) => {
	switch (groupType) {
		case AcctGroupTypeOption.institutionId:
			return TQLPropertyKey.institution;
		case AcctGroupTypeOption.entityId:
			return TQLPropertyKey.entityId;
		case AcctGroupTypeOption.entityRegion:
			return TQLPropertyKey.entityRegion;
		case AcctGroupTypeOption.entityDivision:
			return TQLPropertyKey.entityDivision;
		case AcctGroupTypeOption.entityGroupingId:
			return TQLPropertyKey.entity;
		case AcctGroupTypeOption.regionGroupingId:
			return TQLPropertyKey.region;
		case AcctGroupTypeOption.divisionGroupingId:
			return TQLPropertyKey.division;
		case AcctGroupTypeOption.currency:
			return ParameterType.currency;
		case AcctGroupTypeOption.isManual:
			return TQLPropertyKey.manualAccount;
		case AcctGroupTypeOption.type:
			return TQLPropertyKey.accountType;
		default:
	}
};

export const getFieldPropertyKeyFromCashPositionGrouping = (grouping: string): string => {
	switch (grouping) {
		case CashPositionGrouping.currency:
			return TQLPropertyKey.currency;
		case CashPositionGrouping.institutionId:
			return TQLPropertyKey.institution;
		case CashPositionGrouping.accountId:
			return TQLPropertyKey.account;
		case CashPositionGrouping.entityId:
			return TQLPropertyKey.entityId;
		case CashPositionGrouping.entityRegion:
			return TQLPropertyKey.entityRegion;
		case CashPositionGrouping.entityDivision:
			return TQLPropertyKey.entityDivision;
		case CashPositionGrouping.entity:
			return TQLPropertyKey.entity;
		case CashPositionGrouping.region:
			return TQLPropertyKey.region;
		case CashPositionGrouping.division:
			return TQLPropertyKey.division;
		case CashPositionGrouping.accountType:
			return TQLPropertyKey.accountType;
		default:
			throw new Error(`Invalid CashPositionGrouping: ${grouping}`);
	}
};

export const mapGroupTypeToTQLField = (groupType: string): string => {
	switch (groupType) {
		case 'accountGroupA':
			return TQLPropertyKey.entity;
		case 'accountGroupB':
			return TQLPropertyKey.region;
		case 'accountGroupC':
			return TQLPropertyKey.division;
		case 'type':
			return TQLPropertyKey.accountType;
		default:
			return groupType;
	}
};
